import React, {ReactNode} from "react";
import {Trans} from "@common/i18n/trans";

export function Modal({closeModal, title, children}: {closeModal: () => void, title:string, children?:ReactNode}) {
  return (
    <div className="fixed inset-0 isolate z-modal">
      <div
        className="bg-background/80 inset-0 z-10 h-full w-full absolute backdrop-blur-sm"
        aria-hidden="true" style={{opacity: 1}}></div>
      <div
        className="pointer-events-none absolute inset-0 z-20 flex h-full w-full items-center justify-center"
        role="presentation" style={{opacity: 1, transform: "none"}}>
        <span data-focus-scope-start="true" hidden={false}></span>
        <div role="dialog" tabIndex={-1} aria-labelledby=":r0:-label"
             aria-describedby=":r0:-description" aria-modal="true"
             className="mx-auto pointer-events-auto outline-none flex flex-col overflow-hidden bg-paper w-440 shadow-2xl border max-h-dialog rounded max-w-dialog"
        >
          <div
            className="flex flex-shrink-0 items-center gap-10 font-semibold px-24 py-6 text-main justify-between">
            <h3 id=":r0:-label"
                className="text-sm mr-auto leading-5 opacity-90">
              <Trans message={title} />
            </h3>
            <button type="button"
                    className="focus-visible:ring bg-transparent border-transparent hover:bg-hover disabled:text-disabled disabled:bg-transparent whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center text-sm h-36 w-36 -mr-8 text-muted"
                    aria-label="Dismiss"
                    onClick={closeModal}
            >
              <svg aria-hidden="true" focusable="false"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                   data-testid="CloseOutlinedIcon"
                   className="svg-icon icon-sm" height="1em" width="1em">
                <path
                  d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
              </svg>
            </button>
          </div>

          {children}

        </div>
        <span data-focus-scope-end="true" hidden={false}></span>
      </div>
    </div>
  );
}
