import React, {ReactElement, ReactNode} from "react";
import {Trans} from "@common/i18n/trans";
import {useUser} from "@common/auth/ui/use-user";
import {ProgressCircle} from "@common/ui/progress/progress-circle";
import {SvgIconProps} from "@common/icons/svg-icon";

export function ModalBody(
  {isLoading, title, children, icon}:
  {isLoading: boolean, title: string, children: ReactNode, icon?: ReactElement<SvgIconProps>}
) {
  return (
    <div
      className="px-24 pb-30 overflow-y-auto overflow-x-hidden overscroll-contain text-sm flex-auto text-center">
      {icon}
      <p className="mb-16 mt-10">
        <Trans message={title} />
      </p>
      {isLoading ? (
        <ProgressCircle isIndeterminate className={"mx-auto"}/>
      ) : (
        children
      )}
    </div>
  );
}
